<template>
	<v-sheet
		class="customer"
		id="customer"
		style="height: calc(100vh - 88px)"
		v-if="getPermission('customer::create') || getPermission('customer::edit')"
	>
		<CreateDialog
			:dialog="createDialog"
			v-on:close="
				createDialog = false;
				clearLocalData();
			"
		>
			<template v-slot:title>
				<span>{{ pageTitle() }} </span>
				<span class="orange--text text--darken-4 barcode-font-size">#{{ customer.barcode }}</span>
			</template>
			<template v-slot:body>
				<template v-if="!barcodeLoading">
					<v-stepper flat tile class="item-group-stepper" v-model="stepper">
						<v-stepper-header>
							<v-stepper-step
								color="blue darken-4"
								class="py-2 btx-label"
								:complete="stepper > 1"
								step="1"
							>
								Overview
							</v-stepper-step>

							<v-divider></v-divider>

							<v-stepper-step
								color="blue darken-4"
								class="py-2 btx-label"
								:complete="stepper > 2"
								step="2"
							>
								Contact Persons
							</v-stepper-step>

							<template v-if="propertyCheckbox">
								<v-divider></v-divider>

								<v-stepper-step
									color="blue darken-4"
									class="py-2 btx-label"
									:complete="stepper > 3"
									step="3"
								>
									Addresses
								</v-stepper-step>
							</template>
						</v-stepper-header>
						<v-stepper-items>
							<v-stepper-content class="pt-0" step="1">
								<perfect-scrollbar
									:options="{ suppressScrollX: true }"
									class="scroll custom-box-top-inner-shadow"
									style="max-height: calc(100vh - 185px); position: relative"
								>
									<v-form
										ref="overviewForm"
										v-model.trim="formValid"
										lazy-validation
										v-on:submit.stop.prevent="updateOrCreate()"
									>
										<v-row>
											<v-col md="8">
												<v-row>
													<v-col md="3" class="my-auto py-0">
														<label for="first-name" class="btx-label mt-2">Primary Contact</label>
													</v-col>
													<v-col md="9" class="py-0">
														<v-layout>
															<v-flex class="max-width-100px">
																<SelectInput
																	hide-details
																	:disabled="pageLoading"
																	:loading="pageLoading"
																	:items.sync="listTitle"
																	id="salutation"
																	placeholder="Title"
																	v-model="customer.salutation"
																	v-on:change="updateDefaultPerson(), savetoLocal()"
																>
																</SelectInput>
															</v-flex>
															<v-flex class="mx-2">
																<TextInput
																	hide-details
																	:disabled="pageLoading"
																	:loading="pageLoading"
																	id="first-name"
																	stopSpecialCharacter
																	placeholder="First Name"
																	v-model="customer.first_name"
																	:rules="[vrules.required(customer.first_name, 'First Name')]"
																	:class="{
																		required: !customer.first_name,
																	}"
																	v-on:change="
																		updateDefaultPerson();
																		savetoLocal();
																	"
																></TextInput>
															</v-flex>
															<v-flex>
																<TextInput
																	hide-details
																	stopSpecialCharacter
																	:disabled="pageLoading"
																	:loading="pageLoading"
																	id="last-name"
																	v-on:change="
																		updateDefaultPerson();
																		savetoLocal();
																	"
																	placeholder="Last Name"
																	v-model="customer.last_name"
																></TextInput>
															</v-flex>
														</v-layout>
													</v-col>
													<!-- <v-col md="3" class="my-auto py-0">
														<label for="customer-id" class="btx-label mt-2 required">Customer #</label>
													</v-col>
													<v-col md="9" class="py-0">
														<TextInput
															hide-details
															:disabled="true"
															:loading="pageLoading"
															id="customer-id"
															placeholder="Customer #"
															:rules="[
																vrules.required(customer.barcode, 'Customer #'),
																barcodeError ? false : true,
															]"
															:class="{
																required: !customer.barcode,
															}"
															v-model="customer.barcode"
															v-on:change="validateBarcode(customer.barcode, 'customer'), savetoLocal()"
														>
														</TextInput>
														<span v-if="barcodeError" class="red--text text--darken-1 font-small">{{
															barcodeError
														}}</span>
													</v-col> -->
													<v-col md="3" class="my-auto py-0">
														<label for="company-name" class="btx-label mt-2 required">Company Name</label>
													</v-col>
													<v-col md="9" class="py-0">
														<TextValidateInput
															hide-details
															:disabled="pageLoading"
															:loading="pageLoading"
															id="company-name"
															placeholder="Company Name"
															:rules="[vrules.required(customer.company_name, 'Company Name')]"
															:class="{
																required: !customer.company_name,
															}"
															:validationField="{
																url_type: 'customer',
																filter_type: 'contact',
																field: 'company_name',
															}"
															:parent-id="customer.id"
															:current-id="customer.id"
															show-dropdown
															v-model="customer.company_name"
															v-on:change="savetoLocal()"
															v-on:keyup="updateCustomerDisplayName()"
														></TextValidateInput>
													</v-col>
													<v-col md="3" class="my-auto py-0">
														<label for="display-name" class="btx-label mt-2 required"
															>Display Name
															<TooltipQuestion>
																<template v-slot:text
																	>This name will be displayed on<br />the transactions you create for<br />this
																	customer</template
																>
															</TooltipQuestion>
														</label>
													</v-col>
													<v-col md="9" class="py-0">
														<!-- <TextInput
															hide-details
															:disabled="pageLoading"
															:loading="pageLoading"
															id="display-name"
															:rules="[vrules.required(customer.display_name, 'Display Name')]"
															:class="{
																required: !customer.display_name,
															}"
															placeholder="Display Name"
															@change="savetoLocal()"
															v-model="customer.display_name"
														></TextInput> -->
														<TextValidateInput
															hide-details
															:disabled="pageLoading"
															:loading="pageLoading"
															placeholder="Display Name"
															id="display-name"
															:rules="[vrules.required(customer.display_name, 'Display Name')]"
															:class="{
																required: !customer.display_name,
															}"
															:validationField="{
																url_type: 'customer',
																filter_type: 'contact',
																field: 'display_name',
															}"
															:parent-id="customer.id"
															:current-id="customer.id"
															show-dropdown
															v-model="customer.display_name"
															v-on:change="savetoLocal()"
														></TextValidateInput>
													</v-col>
													<v-col md="3" class="my-auto py-0">
														<label for="uen-number" class="btx-label mt-2">UEN </label>
													</v-col>
													<v-col md="3" class="py-0">
														<TextValidateInput
															hide-details
															:disabled="pageLoading"
															url-type="customer"
															field-name="uen"
															:validation-field="{
																url_type: 'customer',
																filter_type: 'contact',
																field: 'uen',
															}"
															:parent-id="customer.id"
															:current-id="customer.id"
															show-dropdown
															:loading="pageLoading"
															id="uen-number"
															placeholder="UEN Number"
															@change="savetoLocal()"
															v-model="customer.uen"
															v-on:keyup="updateDefaultPerson()"
														>
														</TextValidateInput>
													</v-col>
													<v-col md="3" class="my-auto py-0 text-right">
														<label for="customer-phone-no" class="btx-label mt-2 required"
															>Company phone no</label
														>
													</v-col>
													<v-col md="3" class="py-0">
														<PhoneInput
															required
															hide-details
															:disabled="pageLoading"
															:loading="pageLoading"
															id="customer-phone-no"
															placeholder="Phone No."
															v-model="customer.phone_no"
															:validation-field="{
																url_type: 'customer',
																filter_type: 'contact',
																field: 'phone_number',
															}"
															:parent-id="customer.id"
															:current-id="customer.id"
															@onChange="savetoLocal()"
															@countryChange="countryChange"
															v-on:keyup="updateDefaultPerson()"
														></PhoneInput>
													</v-col>
													<v-col md="3" class="my-auto py-0">
														<label for="customer-email" class="btx-label mt-2 required"
															>Company email address</label
														>
													</v-col>
													<v-col md="3" class="py-0">
														<EmailInput
															hide-details
															:disabled="pageLoading"
															:loading="pageLoading"
															id="customer-email"
															placeholder="Company email address"
															@onChange="savetoLocal()"
															v-model="customer.email"
															:rules="[vrules.required(customer.email, 'Company Email Address')]"
															:class="{
																required: !customer.email,
															}"
															:validation-field="{
																url_type: 'customer',
																filter_type: 'contact',
																field: 'email',
															}"
															:parent-id="customer.id"
															:current-id="customer.id"
															v-on:keyup="updateDefaultPerson()"
														></EmailInput>
													</v-col>
													<v-col md="3" class="my-auto py-0 text-right">
														<label for="postal-code" class="btx-label mt-2 required">Postal Code </label>
													</v-col>
													<v-col md="3" class="py-0">
														<TextInput
															hide-details
															:disabled="pageLoading"
															:loading="pageLoading"
															id="postal-code"
															placeholder="Postal Code"
															@change="savetoLocal()"
															type="number"
															v-mask="'######'"
															v-model.trim="customer.address_postal_code"
															:rules="[
																vrules.required(customer.address_postal_code, 'address postal code'),
																vrules.phoneNumber(customer.address_postal_code, 'address postal code', 6),
															]"
															:class="{
																required: !customer.address_postal_code,
															}"
														></TextInput>
													</v-col>
												</v-row>
												<v-row>
													<v-col md="3" class="my-auto py-0">
														<label for="address-line-1" class="btx-label mt-2">Address Line 1 </label>
													</v-col>
													<v-col md="9" class="py-0">
														<TextInput
															hide-details
															:disabled="pageLoading"
															:loading="pageLoading"
															id="address-line-1"
															@change="savetoLocal()"
															placeholder="Address Line 1"
															v-model="customer.address_line_1"
															:rules="[vrules.required(customer.address_line_1, 'Address line 1')]"
															:class="{ required: !customer.address_line_1 }"
														></TextInput>
													</v-col>
													<v-col md="3" class="my-auto py-0">
														<label for="address-line-2" class="btx-label mt-2">Address Line 2 </label>
													</v-col>
													<v-col md="9" class="py-0">
														<TextInput
															hide-details
															:disabled="pageLoading"
															:loading="pageLoading"
															@change="savetoLocal()"
															id="address-line-2"
															placeholder="Address Line 2"
															v-model="customer.address_line_2"
														></TextInput>
													</v-col>
													<v-col md="3" class="my-auto py-0">
														<label for="unit-number" class="btx-label mt-2">Unit No. </label>
													</v-col>
													<v-col md="3" class="py-0">
														<TextInput
															hide-details
															:disabled="pageLoading"
															:loading="pageLoading"
															id="unit-number"
															@change="savetoLocal()"
															placeholder="Unit No."
															v-model="customer.unit_number"
														>
														</TextInput>
													</v-col>
													<v-col md="3" class="my-auto py-0 text-right">
														<label for="address-country" class="btx-label mt-2">Country </label>
													</v-col>
													<v-col md="3" class="py-0">
														<TextInput
															hide-details
															:disabled="pageLoading"
															:loading="pageLoading"
															@change="savetoLocal()"
															id="address-country"
															placeholder="Country"
															v-model="customer.address_country"
														></TextInput>
													</v-col>
													<v-col md="3" class="mt-3 py-0">
														<label for="customer-remark" class="btx-label mt-2">Remark (Internal)</label>
													</v-col>
													<v-col md="9" class="py-0">
														<TextAreaInput
															:disabled="pageLoading"
															:loading="pageLoading"
															id="customer-remark"
															placeholder="Remark"
															v-model="customer.remark"
															@onChange="savetoLocal()"
														></TextAreaInput>
													</v-col>
													<v-col md="3" class="py-0">
														<label for="customer-attachment" class="btx-label mt-2">Attachments</label>
													</v-col>
													<v-col md="9" class="py-0">
														<FileUpload v-model="customer.files"> </FileUpload>
													</v-col>
													<v-col md="9" class="py-0">
														<AutoCompleteInput
															v-if="false"
															hide-details
															:disabled="pageLoading"
															:loading="pageLoading"
															id="customer-remark"
															placeholder="Remark"
															v-model="customer.remark"
															@onChange="savetoLocal()"
														></AutoCompleteInput>
													</v-col>
												</v-row>
											</v-col>
											<v-col md="4">
												<v-row>
													<v-col md="12" class="my-6 py-0 text-center">
														<Avatar can-change v-model="customer.image"></Avatar>
													</v-col>
												</v-row>
											</v-col>
										</v-row>
									</v-form>
								</perfect-scrollbar>
							</v-stepper-content>
							<v-stepper-content class="pt-0" step="2">
								<perfect-scrollbar
									:options="{ suppressScrollX: true }"
									class="scroll custom-box-top-inner-shadow"
									style="max-height: calc(100vh - 185px); position: relative"
								>
									<v-form
										ref="personForm"
										v-model.trim="formValid"
										lazy-validation
										v-on:submit.stop.prevent="updateOrCreate()"
									>
										<ContactPerson
											:detail="customer"
											v-on:saveContactPerson="updateContactPerson"
											:update-contact="updateCustomer || !!cloneUUID"
											:default-contact-persons="contact_person"
											url="contact"
											type="customer"
											:parent-id="customer.id"
											v-on:saveToLocal="saveToContactLocalData($event)"
										>
										</ContactPerson>
									</v-form>
								</perfect-scrollbar>
							</v-stepper-content>
							<template v-if="propertyCheckbox">
								<v-stepper-content class="pt-0" step="3">
									<perfect-scrollbar
										:options="{ suppressScrollX: true }"
										class="scroll custom-box-top-inner-shadow"
										style="max-height: calc(100vh - 185px); position: relative"
									>
										<v-form
											ref="addressForm"
											v-model.trim="formValid"
											lazy-validation
											v-on:submit.stop.prevent="updateOrCreate()"
										>
											<CustomerBillingAddress
												v-on:saveBillingAddress="updateBillingAddress"
												:update-billing-address="updateBilling"
												:type-uuid="uuid"
												:type="`contact`"
												:detail="customer"
												:parent-id="customer.id"
												:default-billing-address="billing_address"
												v-on:saveToLocal="saveToBillingLocalData($event)"
											>
											</CustomerBillingAddress>
										</v-form>
									</perfect-scrollbar>
								</v-stepper-content>
							</template>
						</v-stepper-items>
					</v-stepper>
				</template>
				<template v-else>
					<div class="mx-20 my-40 text-center">
						<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
					</div>
				</template>
			</template>
			<template v-slot:action>
				<v-btn
					v-if="stepper > 1"
					class="white--text mr-2"
					depressed
					color="blue darken-4"
					tile
					:disabled="pageLoading"
					v-on:click="goBackStepper()"
				>
					Back
				</v-btn>
				<v-btn
					:loading="pageLoading"
					class="white--text mr-2"
					depressed
					color="blue darken-4"
					tile
					:disabled="pageLoading"
					v-on:click="updateOrCreate()"
				>
					Save
					<template v-if="(propertyCheckbox && stepper < 3) || (!propertyCheckbox && stepper < 2)"
						>&amp; Next</template
					>
				</v-btn>
				<v-btn
					v-if="uuid && stepper < 3"
					:loading="pageLoading"
					class="white--text mr-2"
					depressed
					color="blue darken-4"
					tile
					:disabled="pageLoading"
					v-on:click="updateCustomerDirect()"
				>
					Save
				</v-btn>
			</template>
		</CreateDialog>
		<template v-if="manageDesignationDialog">
			<ManageDesignation
				:dialog="manageDesignationDialog"
				:list="designationList"
				v-on:close="manageDesignationDialog = false"
				v-on:success="getOption('designation')"
			></ManageDesignation>
		</template>
	</v-sheet>
</template>
<script>
import { findIndex, toSafeInteger } from "lodash";
import { mapGetters } from "vuex";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import { CreateOrUpdateCustomer, GetCustomer, ValidateContact } from "@/core/lib/contact.lib";
import CustomerBillingAddress from "@/view/components/CustomerBillingAddress";
import TextInput from "@/view/components/TextInput";
import TextAreaInput from "@/view/components/TextAreaInput";
import CreateDialog from "@/view/components/CreateDialog";
import EmailInput from "@/view/components/EmailInput";
import SelectInput from "@/view/components/SelectInput";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { SET_LOCAL_DB } from "@/core/services/store/config.module";
import TooltipQuestion from "@/view/components/TooltipQuestion";
import Avatar from "@/view/components/Avatar";
import ManageDesignation from "@/view/components/Manage-Designation";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import ContactPerson from "@/view/components/ContactPerson";
import FileUpload from "@/view/components/FileUpload";
import ObjectPath from "object-path";
import { map, isArray } from "lodash";
import { saveData, getData, deleteData } from "@/core/services/local.service";
import TextValidateInput from "@/view/components/TextValidateInput";
import PhoneInput from "@/view/components/PhoneInput.vue";

export default {
	name: "customer-create",
	title: "Create Customer",
	data() {
		return {
			uuid: null,
			cloneUUID: null,
			barcode: null,
			formValid: true,
			createDialog: true,
			countries: [],
			designationList: [],
			manageDesignationDialog: false,
			pageLoading: false,
			propertyCheckbox: true,
			stepper: 1,
			updateCustomer: false,
			updateBilling: false,
			customer: {
				barcode: null,
				id: null,
				uuid: null,
				salutation: "mr",
				first_name: null,
				uen: null,
				last_name: null,
				company_name: null,
				image: null,
				designation: null,
				display_name: null,
				email: null,
				phone_no: null,
				fax: null,
				website: null,
				remark: null,
				address: null,
				files: [],
				address_line_1: null,
				address_line_2: null,
				unit_number: null,
				address_country: "Singapore",
				address_postal_code: null,
			},
			address: {
				id: null,
				uuid: null,
				name: null,
				company_name: null,
				phone_no: null,
				email: null,
				id_number: null,
				location: null,
				latitude: null,
				longitude: null,
				address_line_1: null,
				address_line_2: null,
				address_city: null,
				address_state: null,
				address_country: "Singapore",
				address_postal_code: null,
				description: null,
				address_type: 1,
				has_lat_long: 0,
			},
			billing_address: [],
			contact_person: [
				{
					id: null,
					uuid: null,
					salutation: "mr",
					first_name: null,
					last_name: null,
					display_name: null,
					email: null,
					mobile: null,
					did: null,
					primary: false,
					designation: null,
				},
			],
		};
	},
	components: {
		CreateDialog,
		Avatar,
		TextInput,
		TextAreaInput,
		TooltipQuestion,
		AutoCompleteInput,
		ContactPerson,
		FileUpload,
		EmailInput,
		SelectInput,
		ManageDesignation,
		CustomerBillingAddress,
		TextValidateInput,
		PhoneInput,
	},
	methods: {
		getOption() {
			this.$store.dispatch(SET_LOCAL_DB).then(() => {
				this.initialize();
			});
		},
		updateCustomerDisplayName() {
			// const route_name = ObjectPath.get(this.$route, "name");
			// if (route_name == "customer-update") {
			// 	return false;
			// }
			this.customer.display_name = this.customer.company_name;
			this.updateDefaultPerson();
		},
		updateCustomerDisplayName_old() {
			const route_name = ObjectPath.get(this.$route, "name");

			if (route_name == "customer-update") {
				return false;
			}

			const customer = this.customer;
			if (customer) {
				const display_name = [];
				if (customer.first_name) {
					display_name.push(customer.first_name);
				}
				if (customer.last_name) {
					display_name.push(customer.last_name);
				}
				this.customer.display_name = display_name.join(" ");
				this.updateDefaultPerson();
			}
		},

		initialize() {
			this.designationList = this.localDB("designation", []);
			this.customer.designation = this.designationList[0].text;
		},
		updateDefaultPerson() {
			const route_name = ObjectPath.get(this.$route, "name");
			if (route_name == "customer-update") {
				return false;
			}
			const { salutation, first_name, display_name, last_name, email, designation } = this.customer;
			let defaultIndex = toSafeInteger(findIndex(this.contact_person, { email }));
			if (defaultIndex < 0) {
				defaultIndex = 0;
			}
			const person = this.contact_person[defaultIndex];
			if (!person) {
				return false;
			}
			this.contact_person.splice(defaultIndex, 1, {
				id: person.id,
				uuid: person.uuid,
				salutation,
				first_name,
				last_name,
				email,
				display_name,
				// mobile: phone_no,
				// did: phone_no,
				primary: true,
				designation: designation,
			});
		},
		countryChange(country) {
			this.customer.address_country = country;
		},
		async updateOrCreate() {
			const _this = this;
			if (_this.stepper == 1) {
				const formErrors = _this.validateForm(_this.$refs.overviewForm);
				if (formErrors.length) {
					_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
					return false;
				}
				if (!_this.$refs.overviewForm.validate()) {
					return false;
				}
				const _customer = _this.customer;
				const formField = [
					{
						field: "company_name",
						value: _customer.company_name,
					},
					{
						field: "display_name",
						value: _customer.display_name,
					},
					{
						field: "uen",
						value: _customer.uen,
					},
					{
						field: "phone_number",
						value: _customer.phone_no,
					},
					{
						field: "email",
						value: _customer.email,
					},
				];
				const validatePayload = {
					id: _customer.id,
					filter_type: "contact",
					fields: formField,
					data: null,
				};
				_this.pageLoading = true;
				const { exists, error_message } = await ValidateContact("customer", validatePayload);
				_this.pageLoading = false;
				if (exists) {
					if (error_message) {
						_this.$store.commit(SET_ERROR, [{ model: true, message: error_message }]);
					}
					return false;
				}
				//console.log(exists);
			}
			if (_this.stepper == 2) {
				if (_this.contact_person.length == 0) {
					_this.$store.commit(SET_ERROR, [
						{ model: true, message: "Please Add Atleast One Contact Person." },
					]);
					return false;
				}

				const formErrors = _this.validateForm(_this.$refs.personForm);
				if (formErrors.length) {
					_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
					return false;
				}

				if (!_this.$refs.personForm.validate()) {
					return false;
				}
				const _customer = _this.customer;
				const _contact_person = map(this.contact_person, (row) => {
					return {
						id: row.id,
						fields: [
							{
								field: "did",
								value: row.did,
							},
							{
								field: "display_name",
								value: row.display_name,
							},
							{
								field: "phone_number",
								value: row.mobile,
							},
							{
								field: "email",
								value: row.email,
							},
						],
					};
				});
				const validatePayload = {
					id: _customer.id,
					filter_type: "contact_person",
					fields: null,
					data: _contact_person,
				};
				_this.pageLoading = true;
				const { exists, error_message } = await ValidateContact("customer", validatePayload);
				_this.pageLoading = false;
				if (exists) {
					if (error_message) {
						_this.$store.commit(SET_ERROR, [{ model: true, message: error_message }]);
					}
					return false;
				}
			}
			if (_this.stepper == 3) {
				const formErrors = _this.validateForm(_this.$refs.addressForm);
				if (formErrors.length) {
					_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
					return false;
				}

				if (!_this.$refs.addressForm.validate()) {
					return false;
				}
				const _customer = _this.customer;
				const _addresses = map(this.billing_address, (row) => {
					return {
						id: row.id,
						fields: [
							{
								field: "display_name",
								value: row.display_name,
							},
							{
								field: "phone_number",
								value: row.phone_no,
							},
							{
								field: "email",
								value: row.email,
							},
						],
					};
				});
				const validatePayload = {
					id: _customer.id,
					filter_type: "address",
					fields: null,
					data: _addresses,
				};
				_this.pageLoading = true;
				const { exists, error_message } = await ValidateContact("customer", validatePayload);
				_this.pageLoading = false;
				if (exists) {
					if (error_message) {
						_this.$store.commit(SET_ERROR, [{ model: true, message: error_message }]);
					}
					return false;
				}
			}
			let maxStepper = 3;
			if (!_this.propertyCheckbox) {
				maxStepper = 2;
			}

			if (_this.stepper < maxStepper) {
				_this.stepper++;
				return false;
			}
			if (!_this.billing_address.length) {
				_this.$store.commit(SET_ERROR, [
					{ model: true, message: "Please Add Atleast One Billing Address." },
				]);
				return false;
			}

			try {
				_this.pageLoading = true;
				const files = map(this.customer.files, (row) => {
					return { ...row, file: null };
				});
				let _Person_primary_count = 0;
				const _person = map(this.contact_person, (row) => {
					if (row.primary) {
						_Person_primary_count++;
					}
					if (_Person_primary_count > 1) {
						return { ...row, primary: 0 };
					}
					return { ...row, primary: row.primary ? 1 : 0 };
				});
				let _address_primary_count = 0;
				const _billing_address = map(this.billing_address, (row) => {
					if (row._address_primary_count) {
						_address_primary_count++;
					}
					if (_address_primary_count > 1) {
						return { ...row, primary: 0 };
					}
					return { ...row, primary: row.primary ? 1 : 0 };
				});
				const formData = {
					..._this.customer,
					barcode: null,
					attachment: files,
					persons: _person,
					billing_address: _billing_address,
				};

				const { uuid } = await CreateOrUpdateCustomer(formData);

				_this.$router.replace({
					name: "customer-detail",
					params: { uuid },
					query: { t: new Date().getTime() },
				});
				if (this.uuid) {
					_this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Customer has been Updated." },
					]);
				} else {
					_this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Customer has been Created." },
					]);
				}
				// delete local storage data
				this.clearLocalData();
			} catch (error) {
				_this.logError(error);
			} finally {
				_this.pageLoading = false;
			}
		},
		clearLocalData() {
			deleteData("create_customer");
			deleteData("create_customer_contact_person");
			deleteData("create_customer_billing_address");
		},
		async updateCustomerDirect() {
			const _this = this;
			if (_this.stepper == 1) {
				const formErrors = _this.validateForm(_this.$refs.overviewForm);
				if (formErrors.length) {
					_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
					return false;
				}
				if (!_this.$refs.overviewForm.validate()) {
					return false;
				}
				const _customer = _this.customer;
				const formField = [
					{
						field: "company_name",
						value: _customer.company_name,
					},
					{
						field: "display_name",
						value: _customer.display_name,
					},
					{
						field: "uen",
						value: _customer.uen,
					},
					{
						field: "phone_number",
						value: _customer.phone_no,
					},
					{
						field: "email",
						value: _customer.email,
					},
				];
				const validatePayload = {
					id: _customer.id,
					filter_type: "contact",
					fields: formField,
					data: null,
				};
				_this.pageLoading = true;
				const { exists, error_message } = await ValidateContact("customer", validatePayload);
				_this.pageLoading = false;
				if (exists) {
					if (error_message) {
						_this.$store.commit(SET_ERROR, [{ model: true, message: error_message }]);
					}
					return false;
				}
				//console.log(exists);
			}
			if (_this.stepper == 2) {
				if (_this.contact_person.length == 0) {
					_this.$store.commit(SET_ERROR, [
						{ model: true, message: "Please Add Atleast One Contact Person." },
					]);
					return false;
				}

				const formErrors = _this.validateForm(_this.$refs.personForm);
				if (formErrors.length) {
					_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
					return false;
				}

				if (!_this.$refs.personForm.validate()) {
					return false;
				}
				const _customer = _this.customer;
				const _contact_person = map(this.contact_person, (row) => {
					return {
						id: row.id,
						fields: [
							{
								field: "did",
								value: row.did,
							},
							{
								field: "display_name",
								value: row.display_name,
							},
							{
								field: "phone_number",
								value: row.mobile,
							},
							{
								field: "email",
								value: row.email,
							},
						],
					};
				});
				const validatePayload = {
					id: _customer.id,
					filter_type: "contact_person",
					fields: null,
					data: _contact_person,
				};
				_this.pageLoading = true;
				const { exists, error_message } = await ValidateContact("customer", validatePayload);
				_this.pageLoading = false;
				if (exists) {
					if (error_message) {
						_this.$store.commit(SET_ERROR, [{ model: true, message: error_message }]);
					}
					return false;
				}
			}
			if (_this.stepper == 3) {
				const formErrors = _this.validateForm(_this.$refs.addressForm);
				if (formErrors.length) {
					_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
					return false;
				}

				if (!_this.$refs.addressForm.validate()) {
					return false;
				}
				const _customer = _this.customer;
				const _addresses = map(this.billing_address, (row) => {
					return {
						id: row.id,
						fields: [
							{
								field: "display_name",
								value: row.display_name,
							},
							{
								field: "phone_number",
								value: row.phone_no,
							},
							{
								field: "email",
								value: row.email,
							},
						],
					};
				});
				const validatePayload = {
					id: _customer.id,
					filter_type: "address",
					fields: null,
					data: _addresses,
				};
				_this.pageLoading = true;
				const { exists, error_message } = await ValidateContact("customer", validatePayload);
				_this.pageLoading = false;
				if (exists) {
					if (error_message) {
						_this.$store.commit(SET_ERROR, [{ model: true, message: error_message }]);
					}
					return false;
				}
			}
			_this.pageLoading = true;
			try {
				const _files = map(this.customer.files, (row) => {
					return { ...row, file: null };
				});
				let _Person_primary_count = 0;
				const _person = map(this.contact_person, (row) => {
					if (row.primary) {
						_Person_primary_count++;
					}
					if (_Person_primary_count > 1) {
						return { ...row, primary: 0 };
					}
					return { ...row, primary: row.primary ? 1 : 0 };
				});
				let _address_primary_count = 0;
				const _billing_address = map(this.billing_address, (row) => {
					if (row._address_primary_count) {
						_address_primary_count++;
					}
					if (_address_primary_count > 1) {
						return { ...row, primary: 0 };
					}
					return { ...row, primary: row.primary ? 1 : 0 };
				});
				const formData = {
					..._this.customer,
					attachment: _files,
					persons: _person,
					billing_address: _billing_address,
				};

				const { uuid } = await CreateOrUpdateCustomer(formData);

				_this.$router.replace({
					name: "customer-detail",
					params: { uuid },
					query: { t: new Date().getTime() },
				});
				if (uuid) {
					_this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Customer has been Updated." },
					]);
				}
				deleteData("create_customer");
				deleteData("create_customer_contact_person");
				deleteData("create_customer_billing_address");
			} catch (error) {
				_this.logError(error);
			} finally {
				_this.pageLoading = false;
			}
		},
		initCustomer({ customer, address, contact_person }) {
			const _this = this;

			_this.barcode = customer.barcode;
			(_this.uuid = customer.uuid),
				_this.$store.dispatch(SET_BREADCRUMB, [
					{ text: "Contacts", disabled: true },
					{ text: "Customer", disabled: true },
					{ text: "Update", disabled: true },
					{ text: _this.barcode, disabled: true },
				]);
			_this.customer = {
				id: customer.id,
				barcode: customer.barcode,
				uuid: customer.uuid,
				salutation: customer.title,
				first_name: customer.first_name,
				last_name: customer.last_name,
				company_name: customer.company_name,
				image: customer.image,
				display_name: customer.display_name,
				email: customer.email,
				phone_no: customer.phone,
				address: customer.address,
				address_line_1: customer.address_line_1,
				address_line_2: customer.address_line_2,
				unit_number: customer.unit_number,
				address_country: customer.address_country,
				address_postal_code: customer.address_postal_code,
				uen: customer.uen,
				fax: customer.fax,
				website: customer.website,
				remark: customer.remark,
			};
			if (address.length) {
				_this.billing_address = [];
				for (let i = 0; i < address.length; i++) {
					_this.billing_address.push({
						id: address[i].id,
						uuid: address[i].uuid,
						address_type: address[i].address_type,
						display_name: address[i].name,
						phone_no: address[i].phone_no,
						email: address[i].email,
						address_line_1: address[i].address_line_1,
						address_line_2: address[i].address_line_2,
						unit_number: address[i].unit_number,
						label: address[i].label,
						address_country: address[i].address_country,
						address_postal_code: address[i].address_postal_code,
						primary: address[i].primary == 1 ? true : false,
					});
				}
			}
			this.updateBilling = true;
			_this.contact_person = [];
			for (let i = 0; i < contact_person.length; i++) {
				_this.contact_person.push({
					id: contact_person[i].id,
					uuid: contact_person[i].uuid,
					salutation: contact_person[i].title,
					first_name: contact_person[i].first_name,
					last_name: contact_person[i].last_name,
					display_name: contact_person[i].display_name,
					designation: contact_person[i].designation,
					email: contact_person[i].email,
					mobile: contact_person[i].phone,
					did: contact_person[i].did,
					primary: contact_person[i].primary == 1 ? true : false,
				});
			}

			if (!_this.contact_person.length) {
				_this.contact_person = [
					{
						id: null,
						uuid: null,
						salutation: "mr",
						first_name: null,
						last_name: null,
						display_name: null,
						email: null,
						mobile: null,
					},
				];
			}
		},
		getCustomer() {
			const _this = this;
			GetCustomer(_this.uuid)
				.then((data) => {
					_this.initCustomer(data);
				})
				.catch((error) => {
					_this.goBack();
					_this.logError(error);
				})
				.finally(() => {
					_this.pageLoading = false;
				});
		},
		getCountries() {
			const countries = localStorage.getItem("countries");
			if (countries) {
				this.countries = JSON.parse(countries);
			} else {
				this.countries = [
					{
						name: "Singapore",
						code: "SG",
						dial_code: "+65",
					},
				];
				localStorage.setItem("countries", JSON.stringify(this.countries));
			}
		},
		pageTitle() {
			if (this.uuid) {
				return "Update Customer";
			}
			return "Create new Customer";
		},
		updateContactPerson(data) {
			this.contact_person = data;
		},
		saveToContactLocalData(data) {
			if (!this.uuid) {
				saveData("create_customer_contact_person", data);
			}
		},
		updateBillingAddress(data) {
			this.billing_address = data;
		},
		saveToBillingLocalData(data) {
			if (!this.uuid) {
				saveData("create_customer_billing_address", data);
			}
		},
		savetoLocal() {
			if (!this.uuid) {
				const create_customer = {
					salutation: this.customer.salutation,
					first_name: this.customer.first_name,
					last_name: this.customer.last_name,
					display_name: this.customer.display_name,
					uen: this.customer.uen,
					company_name: this.customer.company_name,
					email: this.customer.email,
					phone_no: this.customer.phone_no,
					address_line_1: this.customer.address_line_1,
					address_line_2: this.customer.address_line_2,
					unit_number: this.customer.unit_number,
					label: this.customer.label,
					address_postal_code: this.customer.address_postal_code,
				};
				saveData("create_customer", create_customer);
			}
		},
		getFromLocal() {
			const _this = this;
			let localContactPerson = getData("create_customer_contact_person");
			if (localContactPerson && isArray(localContactPerson)) {
				_this.contact_person = [];
				for (let i = 0; i < localContactPerson.length; i++) {
					_this.contact_person.push({
						id: localContactPerson[i].id,
						uuid: localContactPerson[i].uuid,
						salutation: localContactPerson[i].salutation,
						first_name: localContactPerson[i].first_name,
						last_name: localContactPerson[i].last_name,
						display_name: localContactPerson[i].display_name,
						designation: localContactPerson[i].designation,
						email: localContactPerson[i].email,
						mobile: localContactPerson[i].mobile,
						did: localContactPerson[i].did,
						primary: localContactPerson[i].primary == 1 ? true : false,
					});
				}
			}
			//console.log({contact_person:_this.contact_person});
			let localAddress = getData("create_customer_billing_address");
			if (localAddress && isArray(localAddress)) {
				_this.billing_address = [];
				for (let i = 0; i < localAddress.length; i++) {
					_this.billing_address.push({
						id: localAddress[i].id ? localAddress[i].id : null,
						uuid: localAddress[i].uuid ? localAddress[i].uuid : null,
						address_type: localAddress[i].address_type,
						display_name: localAddress[i].display_name,
						phone_no: localAddress[i].phone_no,
						email: localAddress[i].email,
						address_line_1: localAddress[i].address_line_1,
						address_line_2: localAddress[i].address_line_2,
						unit_number: localAddress[i].unit_number,
						label: localAddress[i].label,
						address_country: localAddress[i].address_country,
						address_postal_code: localAddress[i].address_postal_code,
						primary: localAddress[i].primary == 1 ? true : false,
					});
				}
				//console.log({billing:_this.billing_address});
			}
		},
	},
	computed: {
		...mapGetters(["errors", "localDB"]),
		stringId() {
			return this.stringUnique();
		},
	},
	mounted() {
		this.$store.dispatch(SET_BREADCRUMB, [
			{ text: "Customer", disabled: true },
			{ text: "Create", disabled: true },
		]);
		let localSavedProject = getData("create_customer");
		if (localSavedProject) {
			this.customer.salutation = localSavedProject.salutation;
			this.customer.first_name = localSavedProject.first_name;
			this.customer.last_name = localSavedProject.last_name;
			this.customer.display_name = localSavedProject.display_name;
			this.customer.uen = localSavedProject.uen;
			this.customer.company_name = localSavedProject.company_name;
			this.customer.email = localSavedProject.email;
			this.customer.phone_no = localSavedProject.phone_no;
			this.customer.address_line_1 = localSavedProject.address_line_1;
			this.customer.address_line_2 = localSavedProject.address_line_2;
			this.customer.address_line_2 = localSavedProject.address_line_2;
			this.customer.unit_number = localSavedProject.unit_number;
			this.customer.label = localSavedProject.label;
			this.customer.address_postal_code = localSavedProject.address_postal_code;
		}
		this.getFromLocal();
		this.getCountries();
		const { name, params } = this.$route;
		if (name === "customer-update") {
			this.uuid = params.uuid;
			if (!this.uuid) {
				this.goBack();
			}
			this.pageLoading = true;
			this.getCustomer();
		} else {
			this.genrateBarcode("customer").then((output) => {
				this.customer.barcode = output;
			});
		}
		if (this.uuid) {
			this.updateCustomer = true;
		}

		this.initialize();

		this.cloneUUID = ObjectPath.get(this.$route, "query.clone");
	},
	beforeDestroy() {
		this.$store.dispatch(SET_BREADCRUMB, []);
	},
};
</script>
